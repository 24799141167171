<template>
  <div class="row">
    <div class="col-sm-6 col-md-3">
      <div class="card card-stats card-round">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-icon">
              <div class="icon-big text-center icon-primary bubble-shadow-small">
                <i class="fas fa-users"></i>
              </div>
            </div>
            <div class="col col-stats ms-3 ms-sm-0">
              <div class="numbers">
                <p class="card-category">Garantidoras Cadastradas</p>
                <h4 class="card-title">{{ ListGarantidorasGeral.length }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="card card-stats card-round">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-icon">
              <div class="icon-big text-center icon-info bubble-shadow-small">
                <i class="fas fa-user-check"></i>
              </div>
            </div>
            <div class="col col-stats ms-3 ms-sm-0">
              <div class="numbers">
                <p class="card-category">Contratações Vigentes</p>
                <h4 class="card-title">{{ ListPedidosGeral.length }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="card card-stats card-round">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-icon">
              <div class="icon-big text-center icon-success bubble-shadow-small">
                <i class="fas fa-luggage-cart"></i>
              </div>
            </div>
            <div class="col col-stats ms-3 ms-sm-0">
              <div class="numbers">
                <p class="card-category">Contratações Atendimento</p>
                <h4 class="card-title">{{ ListPedidosAtendimento.length }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="card card-stats card-round">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-icon">
              <div class="icon-big text-center icon-secondary bubble-shadow-small">
                <i class="far fa-check-circle"></i>
              </div>
            </div>
            <div class="col col-stats ms-3 ms-sm-0">
              <div class="numbers">
                <p class="card-category">Contratações Negadas</p>
                <h4 class="card-title">{{ ListPedidosNegados.length }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-round">
        <div class="card-header">
          <div class="card-head-row card-tools-still-right">
            <div class="card-title">Faturamento</div>
          </div>
        </div>
        <div class="card-body p-0">
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-primary card-round">
        <div class="card-header">
          <div class="card-head-row">
            <div class="card-title">Controle de Usos ({{ $moment().format('MMMM[/]YYYY') }})</div>
          </div>
        </div>
        <div class="card-body">
          <!-- Pesquisas de Crédito -->
          <div class="card-category">Pesquisas de Crédito</div>
          <div class="d-flex align-items-center justify-content-between mt-2 mb-4">
            <h6 class="m-0">Total Usados {{ ListPedidosPesquisados.length }}</h6>
            <button class="btn btn-sm btn-info" style="background-color: #ffffff; color: #000000;display: none;">
              Visualizar
            </button>
          </div>
          <!-- Assinaturas Digitais -->
          <div class="card-category">Assinaturas Digitais</div>
          <div class="d-flex align-items-center justify-content-between mt-2 mb-4">
            <h6 class="m-0">Total Usados {{ ListPedidosAssinados.length }}</h6>
            <button class="btn btn-sm btn-info" style="background-color: #ffffff; color: #000000;display: none;">
              Visualizar
            </button>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'DashBoardGarantePay',
  components: {

  },
  data: () => ({
    User: {},
    ListGarantidorasGeral:[],
    ListPedidosGeral:[],
    ListPedidosAtendimento:[],
    ListPedidosNegados:[],
    ListPedidosPesquisados:[],
    ListPedidosAssinados:[],
  }),
  methods: {
  },
  created() {
    const valorArmazenado = localStorage.getItem('_useravgar');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);

      axios.get(`/Process/GarGarantidoras/GetAll`)
          .then(response => {
              this.ListGarantidorasGeral = response.data.filter(x=> x.garAtivo);
          });


     axios.get(`/Process/PedPedidos/GetAllValorTotal/0/0/0`) 
                .then(response => {
                    this.ListPedidosGeral = response.data.filter(x=> x.pspId == 3);   
                    this.ListPedidosAtendimento = response.data.filter(x=> x.pspId != 6  && x.pspId != 3 && x.pspId != 4);     
                    this.ListPedidosNegados = response.data.filter(x=> x.pspId == 6 || x.pspId == 4); 
                    this.ListPedidosPesquisados = response.data.filter(x => 
                                                              x.pspId != 1 && 
                                                              new Date(x.pedDataPedido).getMonth() === new Date().getMonth() && 
                                                              new Date(x.pedDataPedido).getFullYear() === new Date().getFullYear());

                   this.ListPedidosAssinados = response.data.filter(x => (x.pspId == 3 || x.pspId == 5 || x.pspId == 7) && 
                                                              new Date(x.pedDataPedido).getMonth() === new Date().getMonth() && 
                                                              new Date(x.pedDataPedido).getFullYear() === new Date().getFullYear()); 
                  });
    }
  }
}
</script>

<style></style>
