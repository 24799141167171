<template>
    <div>
        <!--edit-->
        <div class="container" v-if="EditView">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Usuários</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Cadastro de Usuários</a>
                        </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click="EditNew()">Incluir Novo</a> </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <div class="row">
                                    <div class="col-md-2 col-lg-2" v-show="false">
                                        <div class="form-group">
                                            <label>Identificador</label>
                                            <input v-model="Usuario.cusId" type="text" class="form-control"
                                                placeholder="Id" />
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="TipoPessoas != '' && User!='' && User.garid == 0">
                                        <div class="form-group">
                                            <label>Tipo Pessoa</label>
                                            <select v-model="Usuario.tpeId" class="form-select form-control" id="defaultSelect" @change="TipoPessoaSelected()">
                                                <option :value="null">Selecione</option>
                                                <option v-for="optionTipo in TipoPessoas" :key="optionTipo.tpeId"
                                                    :value="optionTipo.tpeId">{{ optionTipo.tpeNome }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="User!= '' && Usuario.tpeId != '' && Usuario.tpeId != null && User.garid == 0">
                                        <div class="form-group">
                                            <label>Garantidora</label>
                                            <select  v-model="Usuario.pesId" class="form-select form-control" :disabled="User.tpeid === 1" @change="BuscaImobiliarias()">
                                                <option :value=null>Selecione</option>
                                                <option v-for="optionTipo in ListGarantidoras" :key="optionTipo.garId"
                                                    :value="optionTipo.garId">{{ optionTipo.garNomeEmpresa }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="Usuario.tpeId != '' && Usuario.tpeId != null && (Usuario.tpeId == 2)" v-show="false">
                                        <div class="form-group">
                                            <label>Parceiro</label>
                                            <select  v-model="Usuario.pesId" class="form-select form-control" :disabled="User.tpeid === 1 || User.tpeid === 2">
                                                <option :value=null>Selecione</option>
                                                <option v-for="optionTipo in ListParceirosGeral" :key="optionTipo.parId"
                                                    :value="optionTipo.parId">{{ optionTipo.parNomeEmpresa }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4" v-if="User.garid==0 && Usuario.tpeId != null && (Usuario.tpeId == 3)">
                                        <div class="form-group">
                                            <label>Imobiliaria</label>
                                            <select  v-model="Usuario.pesId" class="form-select form-control" :disabled="User.tpeid === 3 || User.tpeid === 2">
                                                <option :value=null>Selecione</option>
                                                <option v-for="optionTipo in ListImobiliariasGeral" :key="optionTipo.imoId"
                                                    :value="optionTipo.imoId">{{ optionTipo.imoNomeEmpresa }}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Perfil Usuário</label>
                                            <select  v-model="Usuario.admTipoUsuario.tusId" class="form-select form-control">
                                                <option :value=null>Selecione</option>
                                                <option v-for="optionTipo in TipoUsuario" :key="optionTipo.tusId"
                                                    :value="optionTipo.tusId">{{ optionTipo.tusNome }}</option>
                                            </select>
                                        </div>
                                    </div>
                                   
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label>Nome</label>
                                            <input v-model="Usuario.cusNome" type="text" class="form-control"
                                                placeholder="Nome" />
                                        </div>
                                    </div>
                                    <div class="col-md-5 col-lg-5">
                                        <div class="form-group">
                                            <label>E-mail</label>
                                            <input v-model="Usuario.cusEmail" type="text" class="form-control"
                                                placeholder="E-mail" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Senha</label>
                                            <div class="input-icon">
                                                <input v-model="Usuario.cusSenha"
                                                    :type="senhaVisivel ? 'text' : 'password'" class="form-control"
                                                    placeholder="Senha" />
                                                <span class="input-icon-addon">
                                                    <i @click="toggleSenhaVisivel()"
                                                        :class="senhaVisivel ? 'fa fa-eye' : 'fa fa-eye-slash'"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Telefone</label>
                                            <input v-model="Usuario.cusTelefone" v-mask="'(##) ####-#####'" type="text"
                                                class="form-control" placeholder="Telefone" />
                                        </div>
                                    </div>
                                    <div class="col-md-3 col-lg-3">
                                        <div class="form-group">
                                            <label>Whatsapp</label>
                                            <input v-model="Usuario.cusWhatsapp" v-mask="'(##) ####-#####'" type="text"
                                                class="form-control" placeholder="Whatsapp" />
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2">
                                        <div class="form-group">
                                            <label>Ativo</label>
                                            <select v-model="Usuario.cusAtivo" class="form-select form-control">
                                                <option value="">Selecione</option>
                                                <option :value=true>Sim</option>
                                                <option :value=false>Não</option>
                                            </select>
                                        </div>
                                    </div>
                                  
                                  
                                    <div class="col-md-4 col-lg-4">
                                        <div class="form-group">
                                            <label for="exampleFormControlFile1">Upload da Imagem</label>
                                            <v-file-input v-model="selectedFile" label="Imagem" variant="underlined"
                                                prepend-icon="mdi-account-circle" accept="image/*"></v-file-input>
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-lg-2" v-if="Usuario.cusUrlImagem !== '' && Usuario.cusUrlImagem !== null">
                                        <div class="form-group">
                                            <img :src="$imgURL + Usuario.cusUrlImagem" style="width: 100px" />
                                            <v-btn icon density="compact" color="red" class="delete-icon"
                                                variant="text">
                                                <v-icon @click="DeleteImagem()"
                                                    style="font-size:22px;">mdi-alpha-x-circle</v-icon>
                                            </v-btn>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-action">
                        <button v-if="Usuario.cusId != 0" type="button" class="btn btn-success" style="color:#ffffff;"
                            @click="Put()">
                            Alterar
                        </button>
                        <button v-else type="button" class="btn btn-success" style="color:#ffffff;" @click="Add()">
                            Salvar
                        </button>
                        <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                    </div>
                </div>
            </div>
        </div>
        <!--grid-->
        <div class="container" v-if="!EditView">
            <div class="page-inner">
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-header">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Cadastro de usuários</h4>
                                    <a href="#" @click="EditNew()" class="btn btn-sm btn-secondary ms-auto">
                                        <i class="fa fa-plus"></i>
                                        Incluir Novo
                                    </a>
                                </div>
                            </div>
                            <div class="card-body" v-if="ListGarantidorasGeral!=''">
                                <v-row>
                                    <v-col cols="12" md="6" style="padding-bottom: 30px;">
                                        <v-text-field v-model="searchValue" label="Pesquisa" variant="underlined"
                                            required></v-text-field>
                                    </v-col>
                                </v-row>
                                <easy-data-table :headers="headers" :items="Usuarios" v-model="itemsSelected"
                                    alternating theme-color="#364f66" table-class-name="customize-table"
                                    header-text-direction="center" body-text-direction="center"
                                    rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-cusUrlImagem="item">
                                        <img v-if="item.cusUrlImagem != '' && item.cusUrlImagem != null"
                                            :src="$imgURL + item.cusUrlImagem"
                                            style="width: 40px;height:auto;border-radius:50px" />
                                        <img v-else src="/images/userNo.png"
                                            style="width: 40px;height:auto;border-radius:50px" />
                                    </template>
                                    <template #item-cusAtivo="item">
                                        <span>{{ item.cusAtivo ? 'Sim' : 'Não' }}</span>
                                    </template>
                                    <template #item-cusDtCadastro="item">
                                        <span>{{ formattedDate(item.cusDtCadastro) }}</span>
                                    </template>
                                    <template #item-garId="item">
                                        <span>{{ item.garId === null ? 'GarantePay' : ListGarantidorasGeral.filter(x=> x.garId == item.garId)[0].garNomeEmpresa }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn icon @click="EditGrid(item)" style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-file-edit</v-icon>
                                            <v-tooltip activator="parent" location="top">Editar</v-tooltip>
                                        </v-btn>
                                        <v-btn icon @click="DeleteGrid(item)" variant="text">
                                            <v-icon class="deliconcor">mdi-delete-forever</v-icon>
                                            <v-tooltip activator="parent" location="top">Excluir</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import EasyDataTable from 'vue3-easy-data-table';
import moment from 'moment'
import { useRoute } from 'vue-router';

export default {
    name: 'CadastroUsuariosView',
    components: {
        EasyDataTable,
        moment
    },
    setup() {
        const route = useRoute();
        const id = route.params.id;

        return {
            id,
        };
    },
    data: () => ({
        EditView: false,
        ListGarantidorasGeral: [],
        ListGarantidoras:[],
        ListImobiliariasGeral:[],
        ListParceirosGeral:[],
        User: {},
        Usuario: {
            cusId: 0,
            cusNome: '',
            cusEmail: '',
            cusTelefone: '',
            cusWhatsapp: '',
            cusSenha: '',
            cusDtCadastro: null,
            tusId: '',
            cusAtivo: '',
            cusUrlImagem: '',
            cliId: null,
            admTipoUsuario: {tusId: ''},
            tpeId:null,
            garId: null,
            pesId:null,
        },
        Usuarios: [],
        TipoUsuario: [],
        headers: [
            { text: "Id", value: "cusId", sortable: true, },
            { text: "Imagem", value: "cusUrlImagem", },
            { text: "Acessos", value: "garId", },
            { text: "Nome", value: "cusNome", sortable: true, },
            { text: "Email", value: "cusEmail", sortable: true, },
            { text: "Telefone", value: "cusTelefone", },
            { text: "Whatsapp", value: "cusWhatsapp", },
            { text: "Data Cadastro", value: "cusDtCadastro", },
            { text: "Tipo", value: "admTipoUsuario.tusNome", sortable: true, },
            { text: "Ativo", value: "cusAtivo", sortable: true, },
            { text: 'Ações', value: 'actions' }
        ],
        searchValue: '',
        itemsSelected: null,
        senhaVisivel: false,
        selectedFile: null,
        ListImobiliarias: [],
        ImobSelected: [],
        showCli: false,
        lblcli: '',
        ListImobiliariasGeral: [],
    }),
    methods: {
        formattedDate(value) {
            return this.$moment(value).format('DD/MM/YYYY')
        },
        toggleSenhaVisivel() {
            this.senhaVisivel = !this.senhaVisivel;
        },
         //edit
        EditNew(){
            this.EditView = true;
            this.Usuario.cusId = 0;
            this.Usuario.cusNome = '';
            this.Usuario.cusEmail = '';
            this.Usuario.cusTelefone = '';
            this.Usuario.cusWhatsapp = '';
            this.Usuario.cusSenha = '';
            this.Usuario.cusDtCadastro = null;
            this.Usuario.tusId = '';
            this.Usuario.cusAtivo = '';
            this.Usuario.cusUrlImagem = '';
            this.Usuario.cliId = null;
            this.Usuario.admTipoUsuario = {tusId: ''};
            this.Usuario.tpeId = this.User.tpeid == 0 ? null : this.User.tpeid;
            this.Usuario.garId = this.User.garid == 0 ? null : this.User.garid;
            this.Usuario.pesId = this.User.pesid == 0 ? null : this.User.pesid;
            this.TipoPessoaSelected();
        },
        EditGrid(item) {
            this.EditView = true;
            if (item.cusId != ''){
                axios.get(`/Process/UsrCadastroUsuarios/GetById/` + item.cusId)
                    .then(response => {
                        this.Usuario = response.data;
                        this.TipoUsuario = this.TipoUsuarioGeral.filter(x=> x.tpeId == this.Usuario.tpeId)

                        if (this.User.garid == 0){
                            this.ListGarantidoras = this.ListGarantidorasGeral;
                        }
                        else{
                            this.ListGarantidoras = this.ListGarantidorasGeral.filter(x => x.garId == this.User.garid)
                        }
                    });
            }
            else{
                this.TipoPessoaSelected();
            }
        },
        EditCancel(){
            this.$router.replace({ path: '/temporario' }).then(() => {
            this.$router.replace({ path: '/CadastroUsuarios' });
        });
        },
        async UploadImg(value) {
            const formData = new FormData();
            formData.append('file', value);
            const uploadResult = await axios.post(`/Process/UploadImg/upload`, formData);
            return uploadResult;
        },
        async Add() {
            const valida = this.Usuarios.filter(x => x.cusEmail.toLowerCase() == this.Usuario.cusEmail.toLowerCase() && x.cusId != this.Usuario.cusId && x.admTipoUsuario.tusId == this.Usuario.tusId)
             //console.log(valida)
             //console.log('usuario')
            // console.log(this.Usuario)
             
            if (valida.length > 0) {
                this.$mensagemAviso("E-mail já cadastrado.")
            }
            else if (this.Usuario.tpeId == 1 && this.Usuario.pesId === null) {
                this.$mensagemAviso("Garantidora Obrigatório.")
            }
            else if (this.Usuario.tpeId == 2 && this.Usuario.pesId === null) {
                this.$mensagemAviso("Garantidora Obrigatório.")
            }
            else if (this.Usuario.tpeId == 2 && this.Usuario.parId === null) {
                this.$mensagemAviso("Parceiro Obrigatório.")
            }
            else if (this.Usuario.tpeId == 3 && this.Usuario.pesId === null) {
                this.$mensagemAviso("Garantidora Obrigatório.")
            }
            else if (this.Usuario.tpeId == 3 && this.Usuario.imoId === null) {
                this.$mensagemAviso("Imobiliária Obrigatório.")
            }
            else if (this.Usuario.admTipoUsuario.tusId === null || this.Usuario.admTipoUsuario.tusId == 0) {
                this.$mensagemAviso("Perfil Usuário Obrigatório.")
            }
            else if (this.Usuario.cusNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Usuario.cusEmail == '') {
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.Usuario.cusSenha == '') {
                this.$mensagemAviso("Senha Obrigatória.")
            }
            else if (this.Usuario.cusAtivo === '') {
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else {
                this.Usuario.cusDtCadastro = moment();
                if (this.Usuario.cusAtivo === "" || this.Usuario.cusAtivo === null) {
                    this.Usuario.cusAtivo = true;
                }
                if (this.User!= '' && this.Usuario.tpeId != '' && this.Usuario.tpeId != null && this.User.garid != 0){
                    this.Usuario.pesId = this.User.garid;
                    this.Usuario.garId = this.User.garid;
                }
               // console.log('Userlogado')
               // console.log(this.User)
               // console.log('usuario')
               // console.log(this.Usuario)
                if (this.selectedFile != '' && this.selectedFile != null) {
                    //  console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Usuario.cusUrlImagem = uploadSuccess.data.nameFile;
                        this.Usuario.tusId = this.Usuario.admTipoUsuario.tusId;
                        axios.post(`/Process/UsrCadastroUsuarios/Add`, this.Usuario)
                            .then(response => {
                                if (response.data > 0) {
                                    this.$mensagemAvisoSucesso("Registro Incluído.")
                                    this.EnviaEmailCadastro(this.Usuario);
                                    this.Get();
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    this.Usuario.cusUrlImagem = "";
                    this.Usuario.tusId = this.Usuario.admTipoUsuario.tusId;
                    if (this.User!= '' && this.Usuario.tpeId != '' && this.Usuario.tpeId != null && this.User.garid != 0){
                        this.Usuario.pesId = this.User.garid;
                        this.Usuario.garId = this.User.garid;
                    }
                    axios.post(`/Process/UsrCadastroUsuarios/Add`, this.Usuario)
                        .then(response => {
                            if (response.data > 0) {
                                this.$mensagemAvisoSucesso("Registro Incluído.")
                                this.EnviaEmailCadastro(this.Usuario);
                                this.Get();
                            }
                        });
                }
            }
        },
        async Put() {
            const valida = this.Usuarios.filter(x => x.cusEmail.toLowerCase() == this.Usuario.cusEmail.toLowerCase() && x.cusId != this.Usuario.cusId && x.admTipoUsuario.tusId == this.Usuario.tusId)

            if (valida.length > 0) {
                this.$mensagemAviso("E-mail já cadastrado.")
            }
            else if (this.Usuario.tpeId == 1 && this.Usuario.pesId === null) {
                this.$mensagemAviso("Garantidora Obrigatório.")
            }
            else if (this.Usuario.tpeId == 2 && this.Usuario.pesId === null) {
                this.$mensagemAviso("Garantidora Obrigatório.")
            }
            else if (this.Usuario.tpeId == 2 && this.Usuario.parId === null) {
                this.$mensagemAviso("Parceiro Obrigatório.")
            }
            else if (this.Usuario.tpeId == 3 && this.Usuario.pesId === null) {
                this.$mensagemAviso("Garantidora Obrigatório.")
            }
            else if (this.Usuario.tpeId == 3 && this.Usuario.imoId === null) {
                this.$mensagemAviso("Imobiliária Obrigatório.")
            }
            else if (this.Usuario.admTipoUsuario.tusId === null || this.Usuario.admTipoUsuario.tusId == 0) {
                this.$mensagemAviso("Perfil Usuário Obrigatório.")
            }
            else if (this.Usuario.cusNome == '') {
                this.$mensagemAviso("Nome Obrigatório.")
            }
            else if (this.Usuario.cusEmail == '') {
                this.$mensagemAviso("E-mail Obrigatório.")
            }
            else if (this.Usuario.cusAtivo === '') {
                this.$mensagemAviso("Ativo Obrigatório.")
            }
            else {
                if (this.User!= '' && this.Usuario.tpeId != '' && this.Usuario.tpeId != null && this.User.garid != 0){
                        this.Usuario.pesId = this.User.garid;
                        this.Usuario.garId = this.User.garid;
                    }
                if (this.selectedFile != '' && this.selectedFile != null) {
                    //  console.log(this.selectedFile[0].size)
                    const uploadSuccess = await this.UploadImg(this.selectedFile[0]);
                    if (uploadSuccess.status == 200) {
                        this.Usuario.cusUrlImagem = uploadSuccess.data.nameFile;
                        this.Usuario.tusId = this.Usuario.admTipoUsuario.tusId;
                        axios.put(`/Process/UsrCadastroUsuarios/Update`, this.Usuario)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Alterado.")
                                    this.Get();
                                }
                            });
                    }
                    else {
                        this.$mensagemErro("Não foi possível carregar imagem.")
                    }
                }
                else {
                    if (this.Usuario.cusUrlImagem != "") {
                        this.Usuario.cusUrlImagem = "0";
                    }
                    if (this.User!= '' && this.Usuario.tpeId != '' && this.Usuario.tpeId != null && this.User.garid != 0){
                        this.Usuario.pesId = this.User.garid;
                        this.Usuario.garId = this.User.garid;
                    }
                    this.Usuario.tusId = this.Usuario.admTipoUsuario.tusId;
                    axios.put(`/Process/UsrCadastroUsuarios/Update`, this.Usuario)
                        .then(response => {
                            if (response.status == 200) {
                                this.$mensagemAvisoSucesso("Registro Alterado.")
                                this.Get();
                            }
                        });
                }
            }
        },
        DeleteImagem() {
            this.Usuario.cusUrlImagem = "";
        },
        TipoPessoaSelected(){
            this.ListGarantidoras = [];
            this.Usuario.pesId = null;
            this.ListParceirosGeral = [];

            if (this.Usuario.tpeId === '' && this.Usuario.tpeId === null){
                this.TipoUsuario = this.TipoUsuarioGeral.filter(x => x.tpeId === null);
            }
            else{
               this.Usuario.admTipoUsuario.tusId = null;
                this.TipoUsuario = this.TipoUsuarioGeral.filter(x => x.tpeId === this.Usuario.tpeId);

                if (this.User.garid == 0){
                    this.ListGarantidoras = this.ListGarantidorasGeral;
                }
                else{
                    this.ListGarantidoras = this.ListGarantidorasGeral.filter(x => x.garId == this.User.garid)
                    this.Usuario.pesId = this.User.garid;
                    this.Usuario.garId = this.User.garid;
                }

            }
            
        },
        EnviaEmailCadastro(usuariocad){
              axios.get(`/Process/CadMensagensPadrao/GetAll`)
                      .then(response => {
                        this.templateEmail = response.data.filter(x=> x.mpdId == 7);
                            let _dadosEmail = {
                                            nomeDe: this.$GarantePayNome,
                                            nomePara: usuariocad.cusNome,
                                            assunto: 'Cadastro Usuário - Sistema GarantePay',
                                            destinatario: usuariocad.cusEmail,
                                            emailResposta: this.$GarantePayEmail,
                                            textoEmail: this.templateEmail[0].mpdTexto
                                                        .replace('[NomeUsuario]',usuariocad.cusNome)
                                                        .replace('[LinkAcesso]', this.$GarantePayLinkAcesso)
                                                        .replace('[UsuarioLogin]', usuariocad.cusEmail)
                                                        .replace('[SenhaAcesso]', usuariocad.cusSenha)
                                                        .replace('[Logo]', '<center><img src="' + this.$imgURL + this.templateEmail[0].mpdUrlImagem + '" style="width: 200px;" /></center>'),
                                            listAnexos: null //{nomeAnexo: '', anexo: '', tipoAnexo: ''}
                                        }
                                axios.post(`/Process/AdmControleSmtp/Enviamail`,_dadosEmail);
            });
        },
        //edit
        DeleteGrid(item) {
            this.$mensagemConfirma('Tem certeza que deseja excluir?')
                .then((result) => {
                    if (result.isConfirmed) {
                        // Lógica para excluir o item
                        const param1 = item.cusId;
                        axios.delete(`/Process/UsrCadastroUsuarios/Delete/${param1}`)
                            .then(response => {
                                if (response.status == 200) {
                                    this.$mensagemAvisoSucesso("Registro Excluido.")
                                    this.Get();
                                }
                            });
                    }
                });
        },
        CarregaPermissao(value, tipo) {
            if (tipo == 3) {
                this.TelasImobAcesso.map(element => {
                    const Permissoes2 = {
                        cusId: value,
                        cdtId: parseInt(element.cdtId),
                        cusTipo: parseInt(element.cusTipo)
                    };
                    return axios.post(`/Process/UsrControlePermissoes/Add`, Permissoes2)
                        .then(response => {
                            if (response.data > 0) {
                                return null;
                            }
                        });
                });
            }
            else if (tipo == 4) {
                this.TelasParcAcesso.map(element => {
                    const Permissoes2 = {
                        cusId: value,
                        cdtId: parseInt(element.cdtId),
                        cusTipo: parseInt(element.cusTipo)
                    };
                    return axios.post(`/Process/UsrControlePermissoes/Add`, Permissoes2)
                        .then(response => {
                            if (response.data > 0) {
                                return null;
                            }
                        });
                });
            }

        },
        BuscaImobiliarias(){
            this.ListImobiliariasGeral = [];
            this.ListImobiliarias = [];
            this.Usuario.garId = this.Usuario.pesId;
            axios.get(`/Process/ImoImobiliarias/GetByGarIdParId/` + this.Usuario.pesId + '/0') 
                .then(response => {
                    if (response.data.length > 0){
                        this.ListImobiliariasGeral = response.data;
                        this.ListImobiliarias = this.ListImobiliariasGeral;
                    }                                            
                });
        },
        async Get() {
               this.EditView = false;
                const valorArmazenado = localStorage.getItem('_useravgar');
                if (valorArmazenado != '') {
                    this.User = JSON.parse(valorArmazenado);
                }
           await axios.get(`/Process/UsrCadastroUsuarios/GetAll`)
                .then(response => {
                    if (this.User.garid == 0 && this.User.tipo == 1){
                        this.Usuarios = response.data;
                    }
                    else if (this.User.garid == 0 && this.User.tipo != 1){
                        this.Usuarios = response.data.filter(x => x.garId == null && x.cusId != 1);
                    }
                    else if (this.User.garid != 0){
                        this.Usuarios = response.data.filter(x => x.tpeId == this.User.tpeid && x.pesId == this.User.pesid);
                    }

                    axios.get(`/Process/GarGarantidoras/GetAll`)
                        .then(response => {
                            this.ListGarantidorasGeral = response.data;
                            console.log('this.ListGarantidorasGeral')
                            console.log(this.ListGarantidorasGeral)
                            console.log(' this.Usuarios')
                            console.log( this.Usuarios)

                            if (this.User.garid != 0){
                                this.TipoPessoaSelected();
                            }
                            
                        });
                    
                });

               await axios.get(`/Process/UsrTipoPessoas/GetAll`)
                        .then(response => {
                            this.TipoPessoas = response.data;

                            if (this.User.tpeid != null && this.User.tpeid != 0){
                                this.TipoPessoas = this.TipoPessoas.filter(x=> x.tpeId === this.User.tpeid)
                            }

                        });

                        await  axios.get(`/Process/AdmTipoUsuario/GetAll`)
                .then(response => {
                    this.TipoUsuarioGeral = response.data.filter(x => x.tusVisivel && x.tusId >= this.User.tipo).sort((a, b) => {
                        const nomeA = a.tusNome || "";
                        const nomeB = b.tusNome || "";
                        return nomeA.localeCompare(nomeB);
                    });      
                    //console.log('this.TipoUsuarioGeral')
                    //console.log(this.TipoUsuarioGeral)        
                });

                await axios.get(`/Process/ImoImobiliarias/GetAll`)
                .then(response => {
                    this.ListImobiliariasGeral = response.data;
                });

                await  axios.get(`/Process/ParParceiros/GetAll`)
                .then(response => {
                    this.ListParceirosGeral = response.data;
                });
           
                if (this.id != '' && this.id != null){
                    var _item = { cusId: this.id}
                    this.EditGrid(_item)
                }
          
        }
    },
    created() {
        this.Get();
    },
}
</script>

<style></style>
