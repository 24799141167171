<template>
  <div class="row">
    <div class="col-sm-6 col-md-3">
      <div class="card card-stats card-round">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-icon">
              <div class="icon-big text-center icon-primary bubble-shadow-small">
                <i class="fas fa-users"></i>
              </div>
            </div>
            <div class="col col-stats ms-3 ms-sm-0">
              <div class="numbers">
                <p class="card-category">Imobiliarias Cadastradas</p>
                <h4 class="card-title">{{ ListImobiliariasGeral.length }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="card card-stats card-round">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-icon">
              <div class="icon-big text-center icon-info bubble-shadow-small">
                <i class="fas fa-user-check"></i>
              </div>
            </div>
            <div class="col col-stats ms-3 ms-sm-0">
              <div class="numbers">
                <p class="card-category">Contratações Vigentes</p>
                <h4 class="card-title">{{ ListPedidosGeral.length }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="card card-stats card-round">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-icon">
              <div class="icon-big text-center icon-success bubble-shadow-small">
                <i class="fas fa-luggage-cart"></i>
              </div>
            </div>
            <div class="col col-stats ms-3 ms-sm-0">
              <div class="numbers">
                <p class="card-category">Contratações Atendimento</p>
                <h4 class="card-title">{{ ListPedidosAtendimento.length }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-sm-6 col-md-3">
      <div class="card card-stats card-round">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col-icon">
              <div class="icon-big text-center icon-secondary bubble-shadow-small">
                <i class="far fa-check-circle"></i>
              </div>
            </div>
            <div class="col col-stats ms-3 ms-sm-0">
              <div class="numbers">
                <p class="card-category">Contratações Negadas</p>
                <h4 class="card-title">{{ ListPedidosNegados.length }}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-md-8">
      <div class="card card-round">
        <div class="card-header">
          <div class="card-head-row card-tools-still-right">
            <div class="card-title">Faturamento</div>
          </div>
        </div>
        <div class="card-body p-0">
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="card card-primary card-round" v-if="PlanoGarantidora!='' && GarantidoraSerasa != '' && GarantidoraAssinatura!=''" >
        <div class="card-header">
          <div class="card-head-row">
            <div class="card-title">Controle de Usos ({{ $moment().format('MMMM[/]YYYY') }})</div>
          </div>
        </div>
        <div class="card-body">
          <h3>Plano: {{ PlanoGarantidora.cpgNome  }}</h3>
          <!-- Pesquisas de Crédito -->
          <div class="card-category">Pesquisas de Crédito</div>
          <div class="d-flex align-items-center justify-content-between mt-2 mb-4">
            <h6 class="m-0">Usados {{ ListPedidosPesquisados.length }} de {{ GarantidoraSerasa[0].gseQtdeMensal + GarantidoraSerasa[0].gseQtdeGratisMensal + totalCpc2 }}</h6>
            <router-link to="/RecargaConsultas" class="btn btn-sm btn-info" style="background-color: #ffffff; color: #000000;">
              Contratar
            </router-link>
          </div>
          <!-- Assinaturas Digitais -->
          <div class="card-category">Assinaturas Digitais</div>
          <div class="d-flex align-items-center justify-content-between mt-2 mb-4">
            <h6 class="m-0">Usados {{ ListPedidosAssinados.length }} de {{ GarantidoraAssinatura[0].gasQtdeMensal + GarantidoraAssinatura[0].gasQtdeGratisMensal + totalCpc1 }}</h6>
            <router-link to="/RecargaConsultas" class="btn btn-sm btn-info" style="background-color: #ffffff; color: #000000;">
              Contratar
            </router-link>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
import axios from 'axios';
export default {
  name: 'DashBoardGarantidora',
  components: {

  },
  data: () => ({
    User: {},
    ListImobiliariasGeral: [],
    ListPedidosGeral: [],
    ListPedidosAtendimento: [],
    ListPedidosNegados: [],
    ListPedidosPesquisados: [],
    ListPedidosAssinados: [],
    PlanoGarantidora:[],
    GarantidoraSerasa:[],
    GarantidoraAssinatura:[],
    GarantidoraPgtoConsulta:[],
    totalCpc1:0,
    totalCpc2:0,
  }),
  methods: {
  },
  created() {
    const valorArmazenado = localStorage.getItem('_useravgar');
    if (valorArmazenado != '') {
      this.User = JSON.parse(valorArmazenado);

      axios.get(`/Process/ImoImobiliarias/GetByGarIdParId/` + this.User.garid + `/0`)
        .then(response => {
          this.ListImobiliariasGeral = response.data.filter(x => x.imoAtivo);
        });


      axios.get(`/Process/PedPedidos/GetAllValorTotal/` + this.User.garid + '/' + this.User.tpeid + '/' + this.User.pesid)
        .then(response => {
          this.ListPedidosGeral = response.data.filter(x => x.pspId == 3);
          this.ListPedidosAtendimento = response.data.filter(x => x.pspId != 6 && x.pspId != 3 && x.pspId != 4);
          this.ListPedidosNegados = response.data.filter(x => x.pspId == 6 || x.pspId == 4);
          this.ListPedidosPesquisados = response.data.filter(x => 
                                                              x.pspId != 1 && 
                                                              new Date(x.pedDataPedido).getMonth() === new Date().getMonth() && 
                                                              new Date(x.pedDataPedido).getFullYear() === new Date().getFullYear());

          this.ListPedidosAssinados = response.data.filter(x => (x.pspId == 3 || x.pspId == 5 || x.pspId == 7) && 
                                                              new Date(x.pedDataPedido).getMonth() === new Date().getMonth() && 
                                                              new Date(x.pedDataPedido).getFullYear() === new Date().getFullYear());
        });


       axios.get(`/Process/GarGarantidoraAssinatura/GetByGarId/` + this.User.garid)
        .then(response => {
          if (response.data != null){
            this.GarantidoraAssinatura = response.data;
            //console.log('this.GarantidoraAssinatura');
            //console.log(this.GarantidoraAssinatura);
          }
        });

        axios.get(`/Process/GarGarantidoraSerasa/GetByGarId/` + this.User.garid)
        .then(response => {
          if (response.data != null){
            this.GarantidoraSerasa = response.data;
            //console.log('this.Serasa');
            //console.log(this.GarantidoraSerasa);
          }
        });

      axios.get(`/Process/GarGarantidoraPlanos/GetByGarId/` + this.User.garid)
        .then(response => {
          if (response.data != null){
            axios.get(`/Process/CadPlanosGarantePay/GetById/` + response.data[0].cpgId)
                .then(response => {
                  this.PlanoGarantidora = response.data;
                });
          }
        });

        axios.get(`/Process/GarGarantidoraPgtoConsulta/GetByGarId/` + this.User.garid)
          .then(response => {
            if (response.data != null) {
              this.GarantidoraPgtoConsulta = response.data;
              console.log('this.GarantidoraPgtoConsulta', this.GarantidoraPgtoConsulta);

              // Para cpcId 1: soma de gpaQtdeSaldo dos itens com gpaQtdeSaldo > 0 e gpaStatusPgto == 1
              this.totalCpc1 = this.GarantidoraPgtoConsulta
                .filter(item => item.cpcId === 1 && item.gpaQtdeSaldo > 0 && item.gpaStatusPgto === 1)
                .reduce((acc, item) => acc + item.gpaQtdeSaldo, 0);

              // Para cpcId 2: soma de gpaQtdeSaldo dos itens com gpaQtdeSaldo > 0 e gpaStatusPgto == 1
              this.totalCpc2 = this.GarantidoraPgtoConsulta
                .filter(item => item.cpcId === 2 && item.gpaQtdeSaldo > 0 && item.gpaStatusPgto === 1)
                .reduce((acc, item) => acc + item.gpaQtdeSaldo, 0);
              
              //console.log("Total para cpcId 1:", this.totalCpc1);
             // console.log("Total para cpcId 2:", this.totalCpc2);
            }
          });


    }
  }
}
</script>

<style></style>
