<template>
    <div>
        <!--Edit-->
        <Loader v-if="isLoading"></Loader>
        <div class="container">
            <div class="page-inner">
                <div class="page-header">
                    <h3 class="fw-bold mb-3">Recarga de Consultas</h3>
                    <ul class="breadcrumbs mb-3">
                        <li class="nav-home"> <router-link to="/home"><i class="fa fa-home"></i></router-link> </li>
                        <li class="separator"><i class="fa fa-chevron-right"></i> </li>
                        <li class="nav-item"><a href="#" @click.prevent="EditCancel()">Recarga de Consultas de
                                crédito</a>
                        </li>
                    </ul>
                </div>
                <div class="row">
                    <div class="col-md-12">
                        <div class="card">
                            <div class="card-body">
                                <v-form>
                                    <v-container>
                                        <div class="row">
                                            <!-- Seleção de Garantidora -->
                                            <div class="col-md-3 col-lg-3" v-if="User.garid === 0">
                                                <div class="form-group">
                                                    <label>Garantidora</label>
                                                    <select v-model="Garantidora" class="form-select form-control"
                                                        @change="BuscaListaProdutos()">
                                                        <option :value="null">Selecione</option>
                                                        <option v-for="optionTipo in ListGarantidorasGeral"
                                                            :key="optionTipo.garId" :value="optionTipo">
                                                            {{ optionTipo.garNomeEmpresa }}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>

                                            <!-- Listagem dos Produtos Consultas -->
                                            <div class="col-md-12 col-lg-12"
                                                v-if="ProdutosConsultasFor && ProdutosConsultasFor.length">
                                                <!-- Cabeçalho da "tabela" -->
                                                <div class="row font-weight-bold mb-2" style="background-color: #cccccc;margin-top: 30px;padding: 10px;">
                                                    <div class="col-md-3">Produto</div>
                                                    <div class="col-md-3">Valor Unitário</div>
                                                    <div class="col-md-3">Quantidade</div>
                                                    <div class="col-md-3">Total</div>
                                                </div>

                                                <!-- Itens (v-for) -->
                                                <div class="row mb-2" v-for="produto in ProdutosConsultasFor" :key="produto.cpcId">
                                                    <div class="col-md-3">
                                                        {{ produto.cpcNomeProduto }}
                                                    </div>
                                                    <div class="col-md-3"> 
                                                        R$ {{  formatarValor(produto.cpcValor) }}
                                                    </div>
                                                    <div class="col-md-3">
                                                        <input type="number" v-model.number="produto.cpcQtde"
                                                            class="form-control" min="0" />
                                                    </div>
                                                    <div class="col-md-3">
                                                        R$ {{  formatarValor((produto.cpcValor * produto.cpcQtde)) }}
                                                    </div>
                                                </div>

                                                <!-- Total Geral -->
                                                <div class="row mt-3">
                                                    <div class="col-md-12 text-right" style="font-size: 15px; padding-top: 20px;padding-bottom: 20px;">
                                                        <strong>Total Geral: R$ {{  formatarValor(totalGeral) }}</strong>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12 col-lg-12" v-if="totalGeral!=0" style="text-align: right;">
                                                <a href="#" @click="EditCancel()" class="btn btn-link">Cancelar</a>
                                                <button type="button" class="btn btn-success" style="color:#ffffff;" @click="Contratar()">
                                                    Contratar
                                                </button>
                                            </div>
                                        </div>
                                    </v-container>
                                </v-form>
                            </div>
                            <div class="card-header" v-if="ContratacaoAndamento!= '' && Garantidora !=''">
                                <div class="d-flex align-items-center">
                                    <h4 class="card-title">Contratações Pendentes de Pagamento</h4>
                                </div>
                            </div>
                            <div class="card-body" v-if="ContratacaoAndamento!= '' && Garantidora !=''">
                                <easy-data-table :headers="headers" :items="GridContratacao" v-model="itemsSelected" alternating
                                    theme-color="#364f66" table-class-name="customize-table" header-text-direction="center"
                                    body-text-direction="center" rows-per-page-message="Linhas por pagina" :search-value="searchValue">
                                    <template #item-ultimoStatus="item">
                                        <span>{{ item.ultimoStatus == 0 ? 'Pendente' : 'Pago' }}</span>
                                    </template>
                                    <template #item-valorTotal="item">
                                        <span> R$ {{ formatarValor(item.valorTotal) }}</span>
                                    </template>
                                    <template #item-actions="item">
                                        <v-btn v-if="item.ultimoLink" :href="item.ultimoLink" target="_blank" icon style="margin-right: 10px;" variant="text">
                                            <v-icon class="editiconcor">mdi-credit-card</v-icon>
                                            <v-tooltip activator="parent" location="top">Pagamento</v-tooltip>
                                        </v-btn>
                                    </template>
                                </easy-data-table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import moment from 'moment';
import Loader from '../../components/PageLoader.vue'
import EasyDataTable from 'vue3-easy-data-table';

export default {
    name: 'RecargaConsultasView',
    components: {
        moment,
        Loader,
        EasyDataTable
    },
    data: () => ({
        isLoading: false,
        User: [],
        NomeTela: 'Recarga Consultas',
        InfoTela: 'Recarga Consultas',
        //dados
        Garantidora: {},
        ListGarantidorasGeral: [],
        ProdutosConsultas: [],
        Serasa: {
            gseId: 0,
            grtId: 0,
            cdtId: 0,
            gsePropriedade: 0,
            gseQtdeMensal: '',
            gseCustoPesExtra: null,
            gseQtdeGratisMensal: 0,
            gseRangeAprovacao: 0,
            garId: 0
        },
        Processos: {
            gprId: 0,
            grtId: 0,
            cdtId: 0,
            gprPropriedade: 0,
            gprQtdeMensal: '',
            gprCustoPesExtra: null,
            gprQtdeGratisMensal: 0,
            garId: 0
        },
        Assinatura: {
            gasId: 0,
            grtId: 0,
            cdtId: 0,
            gasPropriedade: '',
            gasQtdeMensal: '',
            gasCustoPesExtra: 0,
            gasQtdeGratisMensal: 0,
            gasIdRepositorio: '',
            gasToken: '',
            garId: ''
        },
        valorCredito: 0,
        valorAssinatura: 0,
        ProdutosConsultasFor: [],
        headers: [
            { text: "Contratacao", value: "idContratacao", sortable: true, },
            { text: "ValorTotal", value: "valorTotal"},
            { text: "Status", value: "ultimoStatus"},
            { text: "Link", value: "ultimoLink"},
            { text: 'Ações', value: 'actions' }
        ],
        ContratacaoAndamento:[],
        GridContratacao:[],
    }),
    methods: {
        startLoading() {
            this.isLoading = true;
        },
        stopLoading() {
            this.isLoading = false;
        },
        formattedDate(value) {
            if (value != null || value != "") {
                return this.$moment(value).format('DD/MM/YYYY')
            }
            else {
                return "";
            }

        },
        formatarValor(valor) {
            return valor.toLocaleString('pt-BR', { minimumFractionDigits: 2 });
        },
        async BuscaListaProdutos() {
            await axios.get(`/Process/GarGarantidoraSerasa/GetByGarId/` + this.Garantidora.garId)
                .then(response => {
                    this.Serasa = response.data[0];
                    //console.log('this.Serasa')
                    //console.log(this.Serasa)
                });

            await axios.get(`/Process/GarGarantidoraProcessos/GetByGarId/` + this.Garantidora.garId)
                .then(response => {
                    this.Processos = response.data[0];
                    //console.log('this.Processos')
                    //console.log(this.Processos)
                });

            await axios.get(`/Process/GarGarantidoraAssinatura/GetByGarId/` + this.Garantidora.garId)
                .then(response => {
                    this.Assinatura = response.data[0];
                   // console.log('this.Assinatura')
                    //console.log(this.Assinatura)
                });

            this.valorAssinatura = this.Assinatura.gasCustoPesExtra;
            this.valorCredito = this.Processos.gprCustoPesExtra + this.Serasa.gseCustoPesExtra;

            this.ProdutosConsultasFor = this.ProdutosConsultas.map(x => {
                if (x.cpcId === 1) {
                    x.cpcValor = this.valorAssinatura;
                    x.cpcQtde = 0; 
                } else if (x.cpcId === 2) {
                    x.cpcValor = this.valorCredito;
                    x.cpcQtde = 0; 
                } else {
                    x.cpcValor = 0;
                    x.cpcQtde = 0; 
                }
                return x;
            });

            this.EditGrid();
        },
        EditGrid() {
            axios.get(`/Process/GarGarantidoraPgtoConsulta/GetByGarId/` + this.Garantidora.garId)
                .then(response => {
                    this.ContratacaoAndamento = response.data.filter(x=> x.gpaStatusPgto == 0);

                    const grouped = this.ContratacaoAndamento.reduce((acc, item) => {
                        if (!acc[item.gpaIdContratacao]) acc[item.gpaIdContratacao] = [];
                        acc[item.gpaIdContratacao].push(item);
                        return acc;
                        }, {});

                        const result = Object.keys(grouped).map(key => {
                        const groupItems = grouped[key];
                        groupItems.sort((a, b) => new Date(a.gpaDataContratacao) - new Date(b.gpaDataContratacao));
                        const lastItem = groupItems[groupItems.length - 1];
                        const totalValor = groupItems.reduce((sum, record) => sum + parseFloat(record.gpaValorTotal), 0);
                        return {
                            idContratacao: key,
                            valorTotal: totalValor,
                            ultimoStatus: lastItem.gpaStatusPgto,
                            ultimoLink: lastItem.gpaLinkPgto
                        };
                        });

                    this.GridContratacao = result;
                });
        },
        async Contratar(){
           this.startLoading();
            let _idContratacao = this.Garantidora.garId + moment().format('YYYYMMDDTHHmmss');
           await this.ProdutosConsultasFor.forEach(element => {
                if (element.cpcQtde > 0){
                    let _contratacao = {
                                GpaId: 0,
                                GarId: this.Garantidora.garId,
                                CpcId: element.cpcId,
                                GpaIdContratacao: _idContratacao,
                                GpaCodProdutoDelta: element.cpcCodProdutoDelta,
                                GpaQtdeContratada: element.cpcQtde,
                                GpaValorUnitario: element.cpcValor,
                                GpaValorTotal: element.cpcQtde * element.cpcValor,
                                GpaQtdeSaldo: 0,
                                GpaStatusPgto: 0,
                                GpaDataContratacao: moment().format('YYYY-MM-DDTHH:mm:ss')
                                }

                            axios.post(`/Process/GarGarantidoraPgtoConsulta/Add`, _contratacao)
                            .then(response => {
                                if (response.data > 0) {
                                    _contratacao.GpaId = response.data;   
                                        
                                }
                            });
                }
            });
            await axios.post(`/Process/PgtoDeltaPag/CreateInvoiceGarantePay/` +  this.Garantidora.garId + '/' + _idContratacao)
                            .then(response => {
                                if (response.data == 1) {
                                    this.$mensagemAvisoSucesso("Link de Pagamento Gerado.")  
                                }
                                else if (response.data == 2){
                                    this.$mensagemAviso("Erro Cadastro Customer, consulte Adm.")
                                }
                                else if (response.data == 3){
                                    this.$mensagemAviso("Erro na Geração Link Pagamento, consulte Adm.")
                                }
                                else if (response.data == 4){
                                    this.$mensagemAviso("Erro Retorno Link Pagamento , consulte Adm.")
                                }
                                else if (response.data == 5){
                                    this.$mensagemAvisoSucesso("Link de Pagamento Gerado.")  
                                }
                            });
            await this.BuscaListaProdutos();
            this.stopLoading()
        },
        EditCancel(){
            this.$router.go(0);
        },
        Get() {
            const valorArmazenado = localStorage.getItem('_useravgar');
            if (valorArmazenado != '') {
                this.User = JSON.parse(valorArmazenado);
            }
            //console.log('this.User')
            //console.log(this.User)


            axios.get(`/Process/CadProdutosConsulta/GetAll`)
                .then(response => {
                    this.ProdutosConsultas = response.data.filter(x => x.cpcAtivo);
                    //console.log('this.ProdutosConsultas')
                    //console.log(this.ProdutosConsultas)
                });


            axios.get(`/Process/GarGarantidoras/GetAll`)
                .then(response => {
                    this.ListGarantidorasGeral = response.data.filter(x => x.garAtivo);
                    if (this.User.garid != 0) {
                        this.Garantidora = this.ListGarantidorasGeral.filter(x => x.garId == this.User.garid)[0];
                        this.BuscaListaProdutos();
                        this.EditGrid();
                    }
                });
        }
    },
    created() {
        this.Get();

    },
    computed: {
    totalGeral() {
      return this.ProdutosConsultasFor.reduce((total, produto) => {
        return total + (produto.cpcValor * produto.cpcQtde);
      }, 0);
    }
  },
}
</script>

<style scoped>
.tabinfo {
    font-size: 14px;
    text-transform: capitalize;
}
</style>
